@tailwind base;
@tailwind components;
@tailwind utilities;

#root{
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'currys-title', 'monospace', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* FONTS  */
.eyekandy-title{
  font-family: "eyekandy-title";
}
.eyekandy-body{
  font-family: "eyekandy-body";
}
.currys-title{
  font-family: "currys-title";
}
.currys-body{
  font-family: "currys-title";
}
.currys-font-family{
  font-family: "currys-title";
}
.best-buy-title{
  font-family: "best-buy-regular";
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}
.best-buy-body{
  font-family: "best-buy-regular";
}

.rdc-title{
  font-family: sans-serif;
  font-weight: 700;
}
.rdc-body{
  font-family: sans-serif;
  font-weight: 400;
}
.rdc-font-family{
  font-family: sans-serif;
}
.rdc-search-icon{
  border-radius: 9999px;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #001d3d;
}
.rdc-view-in-ar-button{
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: white;
  font-weight: 500;
  text-align: center;
  border-width: 1px;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 8rem;
  height: 2rem;
  position: relative;
  cursor: pointer;
  background-color: #001d3d;
}

.currys-search-icon{
  border-radius: 9999px;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #4c13a2;
}
.currys-view-in-ar-button{
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: white;
  font-weight: 500;
  text-align: center;
  border-width: 1px;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 8rem;
  height: 2rem;
  position: relative;
  cursor: pointer;
  background-color: #4c13a2;
}

/* LANGUAGE BASED POPUP STYLES  */
.ie-popup-hidden{
  position: absolute;
  width: 10rem;
  height: 18.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -23.5rem;
  border-radius: 0.5rem;
  z-index: -1;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 280ms;
  opacity: 0;
  padding: 10px;
}
.ie-popup-visible{
  position: absolute;
  width: 15rem;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -19.5rem;
  border-radius: 0.5rem;
  z-index: 10;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 280ms;
  opacity: 1;
  padding: 10px;
}
.fr-popup-visible{
  position: absolute;
  width: 15rem;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -23.5rem;
  border-radius: 0.5rem;
  z-index: 10;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 280ms;
  opacity: 1;
  padding: 10px;
}
.fr-popup-hidden{
  position: absolute;
  width: 15rem;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -23.5rem;
  border-radius: 0.5rem;
  z-index: -1;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 280ms;
  opacity: 0;
  padding: 10px;
}
.en-popup-hidden{
  position: absolute;
  width: 10rem;
  height: 18.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -23.5rem;
  border-radius: 0.5rem;
  z-index: -1;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 280ms;
  opacity: 0;
  padding: 10px;
}
.en-popup-visible{
  position: absolute;
  width: 15rem;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -19.5rem;
  border-radius: 0.5rem;
  z-index: 10;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 280ms;
  opacity: 1;
  padding: 10px;
}

/* SEARCH RESULTS QR  */
.search-results-qr-hidden{
  opacity: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 280ms;
}
.search-results-qr-visible{
  opacity: 1;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 280ms;
}

/* RUE DUE COMMERCE  */
.Ru-Due-Commerce-FR-shop-by-images{
  filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: gray; 
  -webkit-filter: grayscale(100%);
  padding: 8px;

  cursor: pointer;
  user-select: none;
}
.Ru-Due-Commerce-FR-shop-by-brand-images-container{
  border: 1px solid #e2ebf4;
  border-radius: 7px;
  padding: 32px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  min-height: 10rem;
  max-height: 10rem;
}
.Ru-Due-Commerce-FR-shop-by-cat-images-container{
  border: 1px solid #e2ebf4;
  border-radius: 7px;
  padding: 32px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  min-height: 10rem;
  max-height: 10rem;
}
.Ru-Due-Commerce-FR-shop-by-card-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 16rem;
}
.Ru-Due-Commerce-FR-active-brand{
  border: 1px solid #e2ebf4;
}
.Ru-Due-Commerce-FR-inactive-brand{
  border: 1px solid #e2ebf4;
}
.Ru-Due-Commerce-FR-carousel-buttons-container{
  position: absolute;
  right: 0;
  bottom: -1.5rem;
}
.Ru-Due-Commerce-FR-carousel-right-button-child{
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  justify-content: center;
  display: flex;
  background-color: #ebf4ff;
  align-items: center;
}
.Ru-Due-Commerce-FR-carousel-left-button-child{
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  justify-content: center;
  display: flex;
  background-color: #ebf4ff;
  align-items: center;
}

/* CURRYS IE  */
.Currys-IE-shop-by-images{
  cursor: pointer;
  user-select: none;
  width: 5rem;
}
.Currys-IE-shop-by-brand-images-container{
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 7rem;
  display: flex;
  overflow: hidden;
  background-color: rgb(242 242 242 / var(--tw-bg-opacity));
  border-width: 1px;
}
.Currys-IE-shop-by-cat-images-container{
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 7rem;
  display: flex;
  overflow: hidden;
  background-color: rgb(242 242 242 / var(--tw-bg-opacity));
  border-width: 1px;
  transition-duration: .5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.Currys-IE-shop-by-cat-images-container:hover{
  transform: scale(1.1);
}
.Currys-IE-active-brand{
  transition-duration: .5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  opacity: 1;
  border-color: rgb(132 204 22);
  background-color: rgb(242 242 242);
}
.Currys-IE-inactive-brand{
  opacity: .5;
  border-color: rgb(244 63 94);
  background-color: rgb(242 242 242);
}
.Currys-IE-active-brand:hover{
  transform: scale(1.1);
}
.Currys-IE-shop-by-card-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 10rem;
  width: 14rem;
}
@media(max-width: 768px){
  .Currys-IE-shop-by-card-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 10rem;
    width: 9rem;
  }
}
.Currys-IE-carousel-buttons-container{
  position: absolute;
  right: 0;
  bottom: 0.5rem;
}
.Currys-IE-carousel-right-button-child{
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  justify-content: center;
  display: flex;
  background-color: #ebf4ff;
  align-items: center;
}
.Currys-IE-carousel-left-button-child{
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  justify-content: center;
  display: flex;
  background-color: #ebf4ff;
  align-items: center;
}


/* BEST BUY CA  */
.Best-Buy-CA-shop-by-card-container{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 10rem;
  width: 10rem;
  color: #0046be;
}
.Best-Buy-CA-shop-by-card-container:hover{
  text-decoration-line: underline;
  text-decoration-color: black;
}

.Best-Buy-CA-shop-by-cat-images-container{
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  display: flex;
  overflow: hidden;
}
.Best-Buy-CA-shop-by-images{
  cursor: pointer;
  user-select: none;
  max-width: 6rem;
  max-height: 6rem;
}
.Best-Buy-CA-shop-by-brand-images-container{
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.best-buy-in-ar-button{
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: white;
  font-weight: 500;
  text-align: center;
  border-width: 1px;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 8rem;
  height: 2rem;
  position: relative;
  cursor: pointer;
  background-color: #4c13a2;
}

/* FONTS  */
@font-face {
  font-family: 'currys-title';
  src: local('currys-title'), url(./routes/landing/fonts/currys-title-font.woff) format('woff');
}
@font-face {
  font-family: 'eyekandy-title';
  src: local('eyekandy-title'), url(./routes/landing/fonts/Comfortaa-Bold.ttf) format('woff');
}
@font-face {
  font-family: 'eyekandy-body';
  src: local('eyekandy-body'), url(./routes/landing/fonts/Comfortaa-Regular.ttf) format('woff');
}
@font-face {
  font-family: 'best-buy-regular';
  src: local('best-buy-regular'), url(./routes/landing/fonts/best-buy-regular-font.woff2) format('woff');
}

/* UTILS  */
@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
}

/* ROOT  */
#root{
  transition-property: opacity;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
transition-duration: 150ms;
}