
/* RUE DUE COMMERCE  */
.Ru-Due-Commerce-FR-carousel-container {
  scroll-snap-type: x mandatory;
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: fit-content;
  margin-top: 2rem;
}
.Ru-Due-Commerce-FR-carousel-container::-webkit-scrollbar {
  display: none;
}

/* CURRYS IE  */
.Currys-IE-carousel-container {
  scroll-snap-type: x mandatory;
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 6px;
}
.Currys-IE-carousel-container::-webkit-scrollbar {
  display: none;
}

/* BEST BUY CA  */
.Best-Buy-CA-carousel-container{
  scroll-snap-type: x mandatory;
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.Best-Buy-CA-carousel-container::-webkit-scrollbar {
  display: none;
}










.carousel-container {
  scroll-snap-type: x mandatory;
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 175px;
}

.carousel-container-desktop {
  scroll-snap-type: x mandatory;
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  /* height: fit-content; */
  scroll-behavior: smooth;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.carousel-container-mobile {
  scroll-snap-type: x mandatory;
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  /* height: fit-content; */
  scroll-behavior: smooth;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.carousel-child {
  scroll-snap-align: center;
  display: inline-block;
  border-radius: 3px;
}

.carousel-container::-webkit-scrollbar {
  display: none;
}
.carousel-container-desktop::-webkit-scrollbar {
  display: none;
}
.carousel-container-mobile::-webkit-scrollbar {
  display: none;
}