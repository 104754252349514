
/*

  @Louis: This is applying everywhere to all scrollbars, TODO: Move it into index.

*/
:root {
    --primary: #f8f8f8;
    --secondary: #bababa;
  }
  
  /* Scrollbar styles */
  
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--primary) var(--secondary);
  }
  
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }
  
  *::-webkit-scrollbar-track {
    background: var(--primary);
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
  /*   background: repeating-linear-gradient(
      45deg,
      var(--secondary),
      var(--secondary) 5px,
      var(--primary) 5px,
      var(--primary) 10px
    ); */
    border-radius: 20px;
    border: 3px solid var(--primary);
  }